import React from "react";
// Import Swiper React components
/* eslint-disable import/no-unresolved */
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { EffectCube, Pagination, Navigation } from "swiper";
import parse from "html-react-parser";

import "../../assets/css/lab-projects.css";
import OasisBg from "../../assets/img/lab/oasis-bg.gif";
import OasisLogo from "../../assets/img/lab/oasis-logo.png";
import XpheresBg from "../../assets/img/lab/xpheres-bg.png";
import XpheresLogo from "../../assets/img/lab/xpheres-logo.png";
import UraLogo from "../../assets/img/lab/ura-logo.png";
import UraBg from "../../assets/img/lab/ura-bg.png";
import PortalLogo from "../../assets/img/lab/portalverse-logo.png";
import PortalBg from "../../assets/img/lab/portalverse-bg.png";

function Projects() {
  const projects = [
    {
      bg: OasisBg,
      logo: OasisLogo,
      class: "flex-col md:flex-row",
      title: "ProjectOasis",
      desc: "A throwback to a nostalgic time where games are light, easy and fun in a 2D metaverse. <br> Participate in DeFi activities and socialize with others in Oasis while immersing yourself in the good old days.",
      telegram: "https://t.me/projectoasis_official",
      youtube: "https://www.youtube.com/c/ProjectOasis_",
      medium: "https://projectoasis.medium.com",
      instagram: "https://www.instagram.com/projectoasis.io/",
      twitter: "https://twitter.com/ProjectOasis_",
      discord: "https://discord.com/invite/478NAfvXre",
      globe: "https://projectoasis.io/",
    },
    {
      bg: XpheresBg,
      logo: XpheresLogo,
      class: "flex-col md:flex-row",
      title: "Xpheres",
      desc: "Xpheres is an NFT collection of beautiful 3D-generative spheres that provide real-life utility to it’s holders.  Holders of Xpheres NFTs are granted access to the hottest parties and events, starting in Bangkok and beyond!",
      instagram: "https://www.instagram.com/xpheresnft/",
      twitter: "https://twitter.com/xpheresnft?s=21&t=tkp0tY_BIIMoKaNoZ1Xj7Q",
      facebook: "https://www.facebook.com/xpheresnft",
      globe: "https://xpheres.io/",
    },

    {
      bg: UraBg,
      logo: UraLogo,
      class: "flex-col md:flex-row-reverse",
      title: "URA",
      desc: "URA stands for Ultimate Rich Alpha. URA is the future to all exclusive access that one will desire when they reach the pinnacle moment of their lives; URA is where high-net-worth individual (HNWI) belongs. With URA Club, our members will be able to truly explore, connect and profit like never before.The reason why we chose to name our project “Alpha” is because it represents the strongest among its hierarchy.",
      instagram: "https://www.instagram.com/uraclubofficial",
      twitter: "https://twitter.com/URA_NFT",
      globe: "https://www.ura.club/",
    },
    {
      bg: PortalBg,
      logo: PortalLogo,
      class: "flex-col md:flex-row-reverse",
      title: "Portalverse",
      desc: "Portalverse network is excited to introduce a decentralized protocol for streaming games and interactive content via innovations in the blockchain. Making the future of game streaming peer to peer, solving the existing issues with gaming, and in turn, pushing forward the development of the best experiences the Metaverse has to offer.",
      twitter: "https://twitter.com/Portalverse_Net",
      discord: "https://discord.com/invite/portalverse",
      medium: "https://medium.com/@PORTALVERSE_NETWORK",
      globe: "https://www.portalverse.net/",
    },
  ];
  function proceed(link) {
    window.location.href = link;
  }
  return (
    <div>
      <Swiper
        effect={"cube"}
        grabCursor={false}
        preventClicks={false}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[EffectCube, Pagination, Navigation]}
        className="swiperProjects"
      >
        {projects &&
          projects.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className={`w-full h-full absolute -z-10`}
                style={{
                  backgroundImage: `linear-gradient(180deg, rgba(10, 15, 31, 0.9) 17.19%, transparent 33.33%, transparent 64.58%, rgba(10, 15, 31, 0.9) 95.19%), url(${item.bg})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              />
              <div
                className={`w-ful h-full text-white flex justify-center items-center md:gap-10 ${item.class}`}
              >
                <div className="w-[40%] md:w-1/4 flex justify-center">
                  <img src={item.logo} alt={`${item.title} logo`} />
                </div>
                <div className="flex flex-col justify-center w-2/3 md:w-1/3 text-center md:text-left">
                  <div className="w-full font-extrabold">{item.title}</div>
                  <div className="w-full font-medium">{parse(item.desc)}</div>
                  <div className="socials flex flex-row gap-3 my-3 items-start justify-center md:justify-start">
                    <div className="">
                      {item.telegram && (
                        <button
                          className="text-lightBlue-400 shadow-lg font-normal h-28 mr-1"
                          type="button"
                          onClick={() => proceed(`${item.telegram}`)}
                        >
                          <svg
                            style={{ color: "white" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            className="bi bi-telegram"
                            viewBox="0 0 20 22"
                          >
                            {" "}
                            <path
                              d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      )}
                      {item.youtube && (
                        <button
                          className="text-lightBlue-400 shadow-lg font-normal h-28"
                          type="button"
                          onClick={() => proceed(`${item.youtube}`)}
                        >
                          <svg
                            style={{ color: "white" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            fill="currentColor"
                            className="bi bi-youtube"
                            viewBox="0 0 24 16"
                          >
                            {" "}
                            <path
                              d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      )}
                      {item.medium && (
                        <button
                          className="text-lightBlue-400 shadow-lg font-normal h-28 mr-1"
                          type="button"
                          onClick={() => proceed(`${item.medium}`)}
                        >
                          <svg
                            style={{ color: "white", marginTop: "-2.5rem" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="31"
                            height="31"
                            viewBox="0 0 32 32"
                          >
                            <path
                              d="M4.469 8.894c0.038-0.369-0.106-0.738-0.381-0.988l-2.819-3.4v-0.506h8.762l6.775 14.856 5.956-14.856h8.356v0.506l-2.412 2.313c-0.206 0.156-0.313 0.419-0.269 0.675v17c-0.044 0.256 0.063 0.519 0.269 0.675l2.356 2.313v0.506h-11.856v-0.506l2.444-2.369c0.238-0.238 0.238-0.313 0.238-0.675v-13.738l-6.794 17.244h-0.919l-7.9-17.244v11.556c-0.069 0.487 0.094 0.975 0.438 1.325l3.175 3.85v0.506h-9v-0.5l3.175-3.856c0.337-0.35 0.494-0.844 0.406-1.325z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      )}
                      {item.instagram && (
                        <button
                          className="text-lightBlue-400 shadow-lg font-normal h-28 mr-1"
                          type="button"
                          onClick={() => proceed(`${item.instagram}`)}
                        >
                          <svg
                            style={{ color: "white" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="38"
                            height="38"
                            fill="currentColor"
                            className="bi bi-instagram"
                            viewBox="0 0 11 22"
                          >
                            <path
                              d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      )}
                      {item.twitter && (
                        <button
                          className="text-lightBlue-400 shadow-lg font-normal h-28 mr-1"
                          type="button"
                          onClick={() => proceed(`${item.twitter}`)}
                        >
                          <svg
                            style={{ color: "white" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            className="bi bi-twitter"
                            viewBox="0 0 10 22"
                          >
                            <path
                              d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      )}
                      {item.facebook && (
                        <button
                          className="text-lightBlue-400 shadow-lg font-normal h-28 mr-1"
                          type="button"
                          onClick={() => proceed(`${item.facebook}`)}
                        >
                          <svg
                            style={{ color: "white" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            className="bi bi-facebook"
                            viewBox="0 0 13 24"
                          >
                            <path
                              d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      )}
                      {item.discord && (
                        <button
                          className="text-lightBlue-400 shadow-lg font-normal h-28 ml-1 mr-1"
                          type="button"
                          onClick={() => proceed(`${item.discord}`)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ color: "white", marginTop: "-2.5rem" }}
                            viewBox="0 0 640 512"
                            fill="currentColor"
                            width="30"
                            height="30"
                          >
                            <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z" />
                          </svg>
                        </button>
                      )}
                      {item.globe && (
                        <button
                          className="text-lightBlue-400 shadow-lg font-normal h-28 ml-1"
                          type="button"
                          onClick={() => proceed(`${item.globe}`)}
                        >
                          <svg
                            style={{ color: "white", marginTop: "-2.5rem" }}
                            width="28"
                            height="28"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M352 256C352 278.2 350.8 299.6 348.7 320H163.3C161.2 299.6 159.1 278.2 159.1 256C159.1 233.8 161.2 212.4 163.3 192H348.7C350.8 212.4 352 233.8 352 256zM503.9 192C509.2 212.5 512 233.9 512 256C512 278.1 509.2 299.5 503.9 320H380.8C382.9 299.4 384 277.1 384 256C384 234 382.9 212.6 380.8 192H503.9zM493.4 160H376.7C366.7 96.14 346.9 42.62 321.4 8.442C399.8 29.09 463.4 85.94 493.4 160zM344.3 160H167.7C173.8 123.6 183.2 91.38 194.7 65.35C205.2 41.74 216.9 24.61 228.2 13.81C239.4 3.178 248.7 0 256 0C263.3 0 272.6 3.178 283.8 13.81C295.1 24.61 306.8 41.74 317.3 65.35C328.8 91.38 338.2 123.6 344.3 160H344.3zM18.61 160C48.59 85.94 112.2 29.09 190.6 8.442C165.1 42.62 145.3 96.14 135.3 160H18.61zM131.2 192C129.1 212.6 127.1 234 127.1 256C127.1 277.1 129.1 299.4 131.2 320H8.065C2.8 299.5 0 278.1 0 256C0 233.9 2.8 212.5 8.065 192H131.2zM194.7 446.6C183.2 420.6 173.8 388.4 167.7 352H344.3C338.2 388.4 328.8 420.6 317.3 446.6C306.8 470.3 295.1 487.4 283.8 498.2C272.6 508.8 263.3 512 255.1 512C248.7 512 239.4 508.8 228.2 498.2C216.9 487.4 205.2 470.3 194.7 446.6H194.7zM190.6 503.6C112.2 482.9 48.59 426.1 18.61 352H135.3C145.3 415.9 165.1 469.4 190.6 503.6V503.6zM321.4 503.6C346.9 469.4 366.7 415.9 376.7 352H493.4C463.4 426.1 399.8 482.9 321.4 503.6V503.6z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}

export default Projects;
