import React from "react";
import { Tooltip, Button } from "@material-tailwind/react";
import parse from "html-react-parser";

import Box from "../assets/img/ventures/incubation/inactive.png";

export default function IncubationBox(props) {
  return (
    <div
      className={`flex flex-col justify-between items-center gap-0 w-1/2 group h-full`}
    >
      {props.item.title ? (
        <Tooltip
          content={props.item.tooltip && parse(props.item.tooltip)}
          className="bg-transparent z-10"
          placement="bottom-start"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0, y: 25 },
          }}
        >
          <Button className="flex flex-col justify-start items-center gap-3">
            <img
              src={Box}
              alt=""
              className="z-10 mix-blend-luminosity group-hover:grayscale-0 grayscale hover:grayscale-0"
            />
            <div className="w-[10rem] lg:w-[13rem] px-5">
              {props.item.title}
            </div>
          </Button>
        </Tooltip>
      ) : (
        <div className="hidden lg:block" />
      )}
      {props.item.right && (
        <div
          className={`hidden lg:block w-[50px] lg:w-[120px] border-white border-[0.2px] relative bottom-[63%] lg:bottom-[74%] left-[100%] ${
            props.item.title ? "block" : "hidden"
          }`}
        />
      )}

      {props.item.rightDesktop && (
        <div
          className={`hidden lg:block w-[50px] lg:w-[164%] border-white border-[0.2px] relative bottom-[63%] lg:bottom-[60%] left-[120%] ${
            props.item.title ? "block" : "hidden"
          }`}
        />
      )}
      {props.item.left && (
        <div
          className={`hidden lg:block w-[50px] lg:w-[120px] border-white border-[0.2px] relative bottom-[78%] lg:bottom-[75%] right-[100%] ${
            props.item.title ? "block" : "hidden"
          }`}
        />
      )}

      {props.item.vertical && (
        <div
          className={`hidden lg:block h-[80px] border-white border-[0.2px] absolute bottom-[65%] right-[10%] ${
            props.item.title ? "block" : "hidden"
          }`}
        />
      )}
      {props.item.verticalBottom && (
        <div
          className={`hidden lg:block h-[120px] border-white border-[0.2px] absolute bottom-[40%] right-[9.8%] ${
            props.item.title ? "block" : "hidden"
          }`}
        />
      )}

      {/* verticalMobile */}
      {props.item.verticalMobile && (
        <div
          className={`block lg:hidden relative testden -bottom-[50px]  -right-[127%] -left-[${props.item.leftPos}]`}
        >
          <div
            className={`block lg:hidden h-[128px] border-white border-[0.2px] absolute bottom-[0%] -right-[120%] ${
              props.item.title ? "block" : "hidden"
            }`}
          />
        </div>
      )}

      {props.item.verticalMobileLeft && (
        <div
          className={`block lg:hidden relative testden -bottom-[15%]  -right-[${props.item.rightPos}] -left-[90%]`}
        >
          <div
            className={`block lg:hidden h-[128px] border-white border-[0.2px] absolute bottom-[0%] -right-[120%] ${
              props.item.title ? "block" : "hidden"
            }`}
          />
        </div>
      )}

      {props.item.leftEnd && (
        <div
          className={`block lg:hidden w-[50px] lg:w-[120px] border-white border-[0.2px] relative bottom-[69%] lg:bottom-[80%] right-[65%] ${
            props.item.title ? "block" : "hidden"
          }`}
        />
      )}
      {props.item.leftMobile && (
        <div
          className={`lg:hidden w-[50px] lg:w-[120px] border-white border-[0.2px] relative bottom-[70%] lg:bottom-[80%] right-[100%] ${
            props.item.title ? "block" : "hidden"
          }`}
        />
      )}

      {props.item.rightMobile && (
        <div
          className={`lg:hidden w-[50px] lg:w-[120px] border-white border-[0.2px] relative bottom-[63%] lg:bottom-[80%] left-[100%] ${
            props.item.title ? "block" : "hidden"
          }`}
        />
      )}
      {props.item.leftLast && (
        <div
          className={`block lg:hidden w-[140px] lg:w-[190px] border-white border-[0.2px] relative bottom-[70%] lg:bottom-[80%] right-[120%] ${
            props.item.title ? "block" : "hidden"
          }`}
        />
      )}
      {props.item.rightEnd && (
        <div
          className={`lg:hidden w-[50px] lg:w-[120px] border-white border-[0.2px] relative bottom-[63%] lg:bottom-[80%] left-[100%] ${
            props.item.title ? "block" : "hidden"
          }`}
        />
      )}
      {props.item.rightMobilePos && (
        <div
          className={`lg:hidden w-[50px] lg:w-[120px] border-white border-[0.2px] relative bottom-[63%] lg:bottom-[80%] left-[100%] ${
            props.item.title ? "block" : "hidden"
          }`}
        />
      )}
    </div>
  );
}
