import React from "react";

import Navbar from "../../components/Navbar";
import Intro from "./intro";
import Mission from "./mission";
import Portfolio from "./portfolio";
import Footer from "../../components/Footer";

function Venture() {
  return (
    <div className="w-full h-screen flex flex-col justify-start bg-primary">
      <Navbar />
      <div className="w-screen flex justify-center items-center">
        <div className="container">
          <Intro />
          <Mission />
          <Portfolio />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Venture;
