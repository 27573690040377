import React from "react";

import { ReactComponent as BGLeft } from "../../assets/img/ventures/Ventures-Left-bg.svg";
import { ReactComponent as BGRight } from "../../assets/img/ventures/Ventures-Right-bg.svg";

function Intro() {
  return (
    <div className="text-white py-44 text-center w-full h-[90vh] flex flex-col justify-center items-center">
      <div className="absolute left-0 md:w-[40vw] w-[70vw]">
        <BGLeft />
      </div>
      <div className="absolute right-1 md:w-[40vw] w-[70vw] ">
        <BGRight />
      </div>

      <div className="flex flex-col justify-center items-center z-10">
        <div className="font-extrabold md:text-[40px] text-[40px] leading-[60px] relative -top-10">
          A Web3 centric venture capital driven by passion
        </div>
        <div className="font-extrabold md:text-[40px] relative -top-10 text-[40px] text-transparent bg-clip-text bg-gradient-to-b from-[#06456A] to-[#00DAB1]">
          to grow the blockchain space.
        </div>

        {/* Button */}

        <div className="flex justify-center items-center w-full relative -bottom-10">
          <div className="bg-gradient-to-b w-[200px] from-[#047F86] to-[#00DAB1] rounded-lg p-4 flex justify-center items-center">
            <a
              href="https://r9c7781s650.typeform.com/to/G7JqLcTy"
              target="blank"
            >
              <button
                type="button"
                className="font-bold text-white text-[16px] md:text-[24px] tracking-wider whitespace-nowrap"
              >
                Contact Us
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
