import React from "react";

import Solana from "../../assets/img/lab/development/solana.png";
import Binance from "../../assets/img/lab/development/binance.png";
import Polygon from "../../assets/img/lab/development/polygon.png";
import Ethereum from "../../assets/img/lab/development/ethereum.png";
import Avalanche from "../../assets/img/lab/development/avalanche.png";
import Harmony from "../../assets/img/lab/development/harmony.png";

function Development() {
  const list = [
    {
      img: Solana,
    },
    {
      img: Binance,
    },
    {
      img: Polygon,
    },
    {
      img: Ethereum,
    },
    {
      img: Avalanche,
    },
    {
      img: Harmony,
    },
  ];
  return (
    <div className="py-10 md:py-32 flex flex-col gap-16">
      <div className="font-extrabold text-4xl text-white">
        <span>
          We support development on the <br />
          following{" "}
        </span>
        <span className="text-transparent bg-clip-text bg-gradient-to-b from-[#047F86] to-[#00DAB1]">
          Blockchains
        </span>
      </div>
      <div
        className="flex flex-wrap justify-center"
        style={{ padding: "40px calc((100% - (355px * 3)) / 2)" }}
      >
        <div className="flex flex-row justify-around flex-wrap items-center gap-5">
          {list &&
            list.map((item, index) => (
              <div
                className="w-1/4 mix-blend-luminosity hover:mix-blend-normal"
                key={index}
              >
                <img src={item.img} alt="" />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Development;
