import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import Navbar from "../../components/Navbar";
import { ReactComponent as BGLeft } from "../../assets/img/homepage/left-bg.svg";
import { ReactComponent as BGRight } from "../../assets/img/homepage/right-bg.svg";
import Twitter from "../../assets/img/homepage/twitter-circle.png";
import Linkedin from "../../assets/img/homepage/linkedin-circle.png";
import "../../assets/css/home.css";

function Home() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <div className="w-full h-screen flex flex-col justify-start bg-primary">
      <Navbar />
      <div className="bg-primary flex-col justify-center items-center">
        <div className="w-screen flex justify-center items-center">
          <div className="container">
            <div className="text-white py-44 text-center w-full flex flex-col justify-center items-center">
              <div className="absolute left-0 md:w-[40vw] w-[70vw]">
                <BGLeft />
              </div>
              <div className="absolute right-1 md:w-[40vw] w-[70vw]">
                <BGRight />
              </div>
              <div className="flex w-full justify-center">
                <div
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  className="relative md:-top-10 font-extrabold text-[25vw] md:text-[15vw] text-transparent bg-clip-text bg-gradient-to-b from-[#06456A] to-[#00DAB1] h1Bold"
                >
                  O
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="550"
                  data-aos-duration="2500"
                  className="relative md:-top-10 font-extrabold text-[25vw] md:text-[15vw] text-transparent bg-clip-text bg-gradient-to-b from-[#06456A] to-[#00DAB1] h1Bold"
                >
                  M
                </div>
                <div
                  data-aos="fade-up"
                  data-aos-delay="1350"
                  data-aos-duration="5500"
                  className="relative md:-top-10 font-extrabold text-[25vw] md:text-[15vw] text-transparent bg-clip-text bg-gradient-to-b from-[#06456A] to-[#00DAB1] h1Bold"
                >
                  V
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Button */}

        <div className="flex justify-center items-center w-full relative -top-48">
          <div className="bg-gradient-to-b w-[200px] from-[#047F86] to-[#00DAB1] rounded-lg p-4 flex justify-center items-center">
            <a href="/venture">
              <button
                type="button"
                className="font-bold text-white text-[16px] md:text-[24px] tracking-wider whitespace-nowrap"
              >
                Enter Now!
              </button>
            </a>
          </div>
        </div>
        {/* footer */}
        <footer className="w-full text-white flex flex-col justify-center gap-5 bg-primary pb-5 fixed bottom-0">
          <div className="flex flex-row justify-center gap-5">
            <a
              href="https://twitter.com/oasismetaverse?s=11&t=TIzt6u7IuQbhfMb1cy4hGg"
              target="__blank"
              className="home-twitter w-[64px] h-[64px]"
              style={{
                backgroundImage: `url(${Twitter})`,
              }}
            />
            <a
              href="https://www.linkedin.com/company/oasismetaverseventure"
              target="__blank"
              className="home-linkedin w-[64px] h-[64px]"
              style={{
                backgroundImage: `url(${Linkedin})`,
              }}
            />
          </div>
          <div className="flex flex-row justify-center items-center gap-5">
            <a className="hover:-mt-1">Legal Notice</a>
            <svg
              width="1"
              height="21"
              viewBox="0 0 1 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="1" height="21" fill="white" />
            </svg>

            <a className="hover:-mt-1">Privacy Policy</a>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Home;
