import React from "react";

import MissionImg from "../../assets/img/homepage/mission.png";

function Mission() {
  return (
    <div className="text-white text-center md:text-left w-full flex flex-col-reverse md:flex-row flex-wrap justify-center items-center p-10 md:p-20 gap-10 md:h-[460px]">
      <div className="w-full md:w-1/3 h-full flex flex-col justify-center">
        <div className="font-extrabold text-4xl text-transparent bg-clip-text bg-gradient-to-b to-[#06456A] from-[#00DAB1]">
          Mission
        </div>
        <div className="font-medium mt-4">
          <p>
            With our team of experts and highly trained analysts, we strive to
            ensure that startups in the Web3 space have the right knowledge and
            tools at their disposal.
          </p>
          <br />
          <p>Financial aid is only the beginning of investments.</p>
        </div>
      </div>
      <div className="w-full md:w-1/3 h-full flex justify-center">
        <img src={MissionImg} alt="mission" className="h-full object-contain" />
      </div>
    </div>
  );
}

export default Mission;
