import React from "react";

export default function Footer() {
  return (
    <footer className="w-full text-white flex flex-col px-[5vw] z-[9999]">
      <hr
        className="my-2"
        style={{
          border: "0.1px solid rgba(0, 190, 175, 0.42)",
        }}
      />
      {/* Footer top sectionb */}
      <div className="flex flex-col md:flex-row justify-around w-full text-left px-5 md:px-10 py-5">
        <div className="w-full md:w-1/3">
          <div className="md:w-[80%]">
            <div className="font-extrabold text-[20px] text-transparent bg-clip-text bg-gradient-to-b from-[#00DAB1] to-[#048388]">
              Join The Oasis Metaverse Ventures Community!
            </div>
            <div className="flex flex-row gap-3 w-full py-2">
              <div>
                <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-b from-[#00DAB1] to-[#048388]">
                  Email:{" "}
                </span>
                <a
                  href="mailto:contact@oasismetaverse.ventures"
                  className="hover:underline"
                >
                  contact@oasismetaverse.ventures
                </a>
              </div>
            </div>
            <br />
            <div className="hidden">
              Donec porttitor risus vel tellus varius efficitur. Vivamus eget
              maximus odio. Nullam consectetur vitae urna vitae blandit.
            </div>
          </div>
          <br />
        </div>
        <div className="w-full md:w-1/3 mt-5 md:mt-0">
          {/* linkedin */}
          <div className="flex flex-row justify-start items-baseline gap-5">
            <a
              className="flex flex-row justify-start items-baseline gap-5"
              href="https://www.linkedin.com/company/oasismetaverseventure"
              target="__blank"
            >
              <svg
                height="20"
                viewBox="0 0 39 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.90764 0C2.19807 0 0 2.14841 0 4.80114C0 7.45386 2.1963 9.65199 4.90402 9.65199C7.61173 9.65199 9.81166 7.45386 9.81166 4.80114C9.81166 2.15023 7.61722 0 4.90764 0ZM28.851 12.7273C24.73 12.7273 22.3721 14.8372 21.2411 16.9354H21.1214V13.2919H13V40H21.4623V26.7791C21.4623 23.2955 21.7309 19.929 26.1342 19.929C30.4743 19.929 30.5377 23.9011 30.5377 26.9993V40H38.9891H39V25.3303C39 18.1521 37.4236 12.7273 28.851 12.7273ZM0.674665 13.2919V40H9.14425V13.2919H0.674665Z"
                  fill="white"
                />
              </svg>

              <div className="font-extrabold text-[20px] text-transparent bg-clip-text bg-gradient-to-b from-[#00DAB1] to-[#048388]">
                Linkedin
              </div>
            </a>
          </div>
          <div className="text-[15px]">
            Check us out on LinkedIn to learn more about what we do and get in
            touch!
          </div>
          <br />
          {/* twitter */}
          <a
            className="flex flex-row justify-start items-baseline gap-5"
            href="https://twitter.com/oasismetaverse?s=11&t=TIzt6u7IuQbhfMb1cy4hGg"
            target="__blank"
          >
            <svg
              height="20"
              viewBox="0 0 50 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M50 4.73498C48.1605 5.53825 46.1832 6.0809 44.1085 6.32504C46.2262 5.07533 47.853 3.09644 48.6187 0.738307C46.6365 1.89572 44.4412 2.7359 42.1045 3.18873C40.2335 1.22607 37.5675 0 34.617 0C28.952 0 24.3587 4.52111 24.3587 10.0978C24.3587 10.8892 24.4495 11.66 24.6245 12.3991C16.0989 11.978 8.54043 7.95767 3.48092 1.84822C2.59792 3.33959 2.09192 5.07411 2.09192 6.92479C2.09192 10.4283 3.90292 13.5191 6.65543 15.3299C4.97392 15.2774 3.39217 14.8231 2.00917 14.0669C2.008 14.109 2.008 14.1513 2.008 14.1939C2.008 19.0863 5.54401 23.1674 10.2368 24.0957C9.37602 24.3266 8.46976 24.4499 7.53426 24.4499C6.87326 24.4499 6.23077 24.3864 5.60427 24.2687C6.90977 28.2804 10.698 31.1999 15.187 31.2811C11.6763 33.9897 7.25327 35.6041 2.44701 35.6041C1.619 35.6041 0.802502 35.5562 0 35.4629C4.53976 38.3282 9.93177 40 15.7248 40C34.5931 40 44.9113 24.613 44.9113 11.2685C44.9113 10.8306 44.9013 10.3953 44.8816 9.96191C46.8856 8.53846 48.6246 6.76014 49.9998 4.73498H50Z"
                fill="white"
              />
            </svg>

            <div className="font-extrabold text-[20px] text-transparent bg-clip-text bg-gradient-to-b from-[#00DAB1] to-[#048388]">
              Twitter
            </div>
          </a>
          <div className="text-[15px]">
            Follow @OASISMETAVERSE to find out about our latest releases and
            what we are up to!
          </div>
        </div>
      </div>

      {/* Footer bottom section */}
      <div className="flex flex-col md:flex-row justify-between py-2 gap-2">
        <div className="text-white text-opacity-40 text-[15px]">
          2022 All rights reserved.
        </div>
        <div className="flex flex-row flex-wrap justify-center md:gap-5">
          <a href="#" className="md:hover:-mt-1 md:w-auto w-1/2 hidden">
            Copyright Notice
          </a>

          <a href="#" className="md:hover:-mt-1 md:w-auto w-1/2 hidden">
            Terms of Use
          </a>

          <a href="#" className="md:hover:-mt-1 md:w-auto w-1/2 hidden">
            Legal & Privacy
          </a>
        </div>
      </div>
    </footer>
  );
}
