import React from "react";

function News() {
  return (
    <div className="w-full flex flex-col justify-start bg-primary md:pt-0">
      <div className="w-screen flex justify-center items-center">
        <div className="container">
          <div className="text-white text-center md:text-left w-full flex flex-col-reverse md:flex-row flex-wrap justify-center items-center p-10 md:p-20 gap-10 md:h-[460px]">
            <div className="w-full md:w-1/3 h-full flex flex-col justify-center">
              <div className="font-extrabold text-4xl text-transparent bg-clip-text bg-gradient-to-b to-[#06456A] from-[#00DAB1] pb-5">
                Ethereum's Progression with Hudson James
              </div>
              <div className="font-medium">
                <p>
                  Hudson Jameson is an Ethereum enthusiast and core developer
                  liaison with a rich background in blockchain projects. He's
                  played an active role in shaping various online communities
                  for decentralized technology, including Ethereum and more
                  recently, Zcash.
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/3 h-full flex justify-center">
              <div className="relative h-full overflow-hidden max-w-full w-full">
                <iframe
                  className="w-full aspect-video ..."
                  src="https://www.youtube.com/embed/TORG6bjXSnY"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
