import React from "react";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import News from "./news";
import Podcast from "./podcast";
import Influencer from "./influencer";

function Media() {
  return (
    <div className="w-full h-screen flex flex-col justify-start bg-primary">
      <Navbar />
      <div className="w-screen flex">
        <div className="container">
          <Influencer />
          <Podcast />
          <News />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Media;
