import React from "react";
import MissionImg from "../../assets/img/media/podcast/playback.png";
import Music from "../../assets/img/media/podcast/music.png";
import Spotify from "../../assets/img/media/podcast/spotify.png";
import Apple from "../../assets/img/media/podcast/apple.png";
import Youtube from "../../assets/img/media/podcast/youtube.png";
import { ReactComponent as BG } from "../../assets/img/media/mediaBg.svg";

function Podcast() {
  return (
    <div className="text-white pt-32 text-left w-full flex flex-col justify-start items-center ">
      <div className="absolute md:-top-[11%]">
        <BG />
      </div>
      <div className="w-full flex md:flex-col justify-start bg-primary">
        <div className="w-screen flex justify-center items-center">
          <div className="container mx-2 2xl:mx-60">
            <div className="text-white text-center md:text-left w-full flex md:flex-row flex-wrap items-center p-10 md:p-20 !pb-0 gap-0 !pt-0">
              <div className="flex flex-col-reverse md:flex md:flex-row">
                <div className="w-full md:w-[20%] md:ml-[1.5rem] h-full flex justify-start">
                  <img
                    src={MissionImg}
                    alt="mission"
                    className="h-full object-contain w-[40%] m-auto md:w-[90%]"
                  />
                </div>
                <div className="mb-5 md:mb-0">
                  <div className="w-full md:w-auto h-full flex flex-col justify-center items-start">
                    <div
                      className="font-bold text-[1.9rem] md:text-[3rem] text-transparent bg-clip-text bg-gradient-to-b to-[#06456A] from-[#00DAB1] text-center
                    flex justify-center w-full md:justify-start"
                    >
                      Coming Soon
                    </div>
                    <div className="font-bold md:text-[2.3vw] flex w-full justify-center">
                      <p className="md:-mt-[0.5rem]">
                        Podcasts for Crypto & Blockchain
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <img
                  src={Music}
                  alt="mission"
                  className="h-full object-contain m-auto w-full"
                />
              </div>
              <div className="w-full m-5">
                <div className="flex flex-row justify-end gap-4 ml-[1.7rem] md:ml-0">
                  <img
                    src={Spotify}
                    alt="spotify"
                    className="object-contain w-[30%] md:w-[10%]"
                  />
                  <img
                    src={Apple}
                    alt="apple"
                    className="object-contain w-[40%] md:w-[16%]"
                  />
                  <img
                    src={Youtube}
                    alt="youtube"
                    className="object-contain w-[30%] md:w-[10%]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Podcast;
