import React from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import NotFound from "./pages/error/NotFound";
import Home from "./pages/home";
import Venture from "./pages/venture";
import Lab from "./pages/lab";
import Media from "./pages/media";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/venture" element={<Venture />} />
        <Route exact path="/lab" element={<Lab />} />
        <Route exact path="/media" element={<Media />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
