import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { useLocation } from "react-router-dom";

import logo from "../assets/img/logo.png";
import logoHover from "../assets/img/logoHover.png";

const navList = [
  {
    name: "Labs",
    href: "https://oasislabs.tech",
  },
  {
    name: "Media",
    href: "/media",
  },
];

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setHover] = useState(false);

  const location = useLocation();
  return (
    <nav className="bg-primary z-50">
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-16">
        <div className="flex items-center justify-between h-24">
          <div className="flex items-center justify-between w-full">
            <div className="flex-shrink-0">
              <a
                href="/"
                onMouseOver={() => setHover(true)}
                onFocus={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                onBlur={() => setHover(false)}
              >
                <img
                  className="h-16"
                  src={isHover ? logoHover : logo}
                  alt="Oasis Metaverse Ventures"
                />
              </a>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {navList &&
                  Object.values(navList).map((item, index) => (
                    <div className="px-5 group">
                      {/* <a
                        key={index}
                        href={item.href}
                        className="group-hover:text-[#00DCB1] text-[18px] font-medium text-white px-3 py-2 rounded-md text-sm tracking-widest"
                      >
                        {location.pathname === item.name ? (
                          <span className="text-red">{item.name}</span>
                        ) : (
                          <span className="text-white">{item.name}</span>
                        )}
                      </a> */}
                      <a key={index} href={item.href} className="">
                        {location.pathname === item.href ? (
                          <span className="group-hover:text-[#00DCB1] text-[18px] text-[#00DCB1] font-medium text-white px-3 py-2 rounded-md text-sm tracking-widest">
                            {item.name}
                          </span>
                        ) : (
                          <span className="group-hover:text-[#00DCB1] text-[18px] font-medium text-white px-3 py-2 rounded-md text-sm tracking-widest">
                            {item.name}
                          </span>
                        )}
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="md:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navList &&
              Object.values(navList).map((item, index) => (
                // <a
                //   key={index}
                //   href={item.href}
                //   className="hover:text-[#00DCB1] text-white block px-3 py-2 rounded-md text-base font-medium tracking-widest"
                // >
                //   {item.name}
                // </a>

                <a
                  key={index}
                  href={item.href}
                  className="hover:text-[#00DCB1] text-white block px-3 py-2 rounded-md text-base font-medium tracking-widest"
                >
                  {location.pathname === item.href ? (
                    <span className="text-[#00DCB1]">{item.name}</span>
                  ) : (
                    <span className="">{item.name}</span>
                  )}
                </a>
              ))}
          </div>
        </div>
      </Transition>
    </nav>
  );
}

export default Navbar;
