import React from "react";

import { ReactComponent as BG } from "../../assets/img/lab/intro-bg.svg";

function Intro() {
  return (
    <div className="text-white py-44 text-center w-full flex flex-col justify-center items-center h-[90vh]">
      <div className="absolute left-0 md:top-0 top-[30%] w-screen">
        <BG />
      </div>

      <div className="flex flex-col justify-center items-center z-10">
        <div className="font-astro-space text-[28px]">OASIS LABS</div>

        <div className="font-extrabold md:text-[60px] text-[40px] leading-[60px]">
          Comprehensive
        </div>
        <div className="font-extrabold md:text-[60px] text-[40px] text-transparent bg-clip-text bg-gradient-to-b from-[#06456A] to-[#00DAB1]">
          Incubation
        </div>
        <div className="text-[20px]">
          Inching today, with giants of tomorrow.
        </div>
      </div>
    </div>
  );
}

export default Intro;
