import React from "react";

import EthosImg from "../../assets/img/lab/ethos.png";

function Ethos() {
  return (
    <div className="text-white text-center md:text-left w-full flex flex-col-reverse md:flex-row flex-wrap justify-center items-center p-10 md:p-20 gap-10 md:h-[460px]">
      <div className="w-full md:w-1/3 h-full flex flex-col justify-center">
        <div className="font-extrabold text-4xl text-white">
          <span>Our </span>
          <span className="text-transparent bg-clip-text bg-gradient-to-b to-[#06456A] from-[#00DAB1]">
            Ethos
          </span>
        </div>
        <div className="font-medium mt-[1rem] lg:mt-0">
          <p>
            We believe in growing new projects and startups from conception to
            completion.
          </p>
          <br />
          <p className="lg:break-words lg:w-[85%]">
            We aim to provide our cohorts with the best possible resources and
            connections to accelerate their exponential growth.
          </p>
        </div>
      </div>
      <div className="w-1/2 md:w-1/5 h-full flex justify-center">
        <img src={EthosImg} alt="ethos" className="h-full object-contain" />
      </div>
    </div>
  );
}

export default Ethos;
