import React from "react";

import evermoon from "../../assets/img/partners/evermoon.png";
// import exverse from "../../assets/img/partners/exverse_logo.svg";
import nibiru from "../../assets/img/partners/nibiru.svg";
import sphereX from "../../assets/img/partners/sphereX.png";
// import bountiehunter from "../../assets/img/partners/Bountie-hunter.png";
import lovelace from "../../assets/img/partners/Lovelace.png";
import projectoasis from "../../assets/img/partners/Projectoasis.png";
import cryptoBlade from "../../assets/img/partners/CryptoBladesKingdom.png";
import starter from "../../assets/img/partners/Starter.png";
import strongNode from "../../assets/img/partners/StrongNode.png";
import portalVerse from "../../assets/img/partners/portalverse.png";

import hana from "../../assets/img/partners/hana.png";
import rainmaker from "../../assets/img/partners/rainmaker.png";
import lava from "../../assets/img/partners/lava.png";
import kontos from "../../assets/img/partners/kontos.png";

import itrival from "../../assets/img/partners/itrival.png";
import lympid from "../../assets/img/partners/lympid.png";
import run from "../../assets/img/partners/run.png";

const portfolio = [
  {
    name: "kontos",
    img: kontos,
    link: "https://www.kontos.io/",
  },
  {
    name: "lava",
    img: lava,
    link: "https://www.lavanet.xyz/",
  },
  {
    name: "nibiru",
    img: nibiru,
    link: "https://nibiru.fi/",
  },
  {
    name: "projectoasis",
    img: projectoasis,
    link: "https://projectoasis.io",
  },
  {
    name: "hana",
    img: hana,
    link: "https://www.hana.network/",
  },

  {
    name: "run",
    img: run,
    link: "https://runesterminal.io/",
  },
  {
    name: "lympid",
    img: lympid,
    link: "https://www.lympid.io/",
  },

  {
    name: "itrival",
    img: itrival,
    link: "https://token.bitrivals.app/",
  },
  {
    name: "evermoon",
    img: evermoon,
    link: "https://www.evermoon.games/",
  },
  {
    name: "rainmaker",
    img: rainmaker,
    link: "https://www.raiinmaker.com/",
  },

  {
    name: "sphereX",
    img: sphereX,
    link: "https://www.sx.xyz/",
  },
  {
    name: "starter",
    img: starter,
    link: "https://starter.xyz",
  },

  {
    name: "cryptoBlade",
    img: cryptoBlade,
    link: "https://cryptobladeskingdoms.io/",
  },

  {
    name: "portalVerse",
    img: portalVerse,
    link: "https://www.portalverse.net/",
  },
  {
    name: "lovelace",
    img: lovelace,
    link: "https://lovelace.world",
  },
  {
    name: "strongNode",
    img: strongNode,
    link: "https://strongnode.io/",
  },

  // {
  //   name: "bountiehunter",
  //   img: bountiehunter,
  //   link: "https://bountiehunter.io",
  // },
];

function Portfolio() {
  return (
    <div className="bg-primary w-full flex flex-col justify-start py-20">
      <div className="flex flex-col">
        <section
          className="max-w-full content bg-"
          style={{
            paddingTop: "calc(26vmax / 10)",
            paddingBottom: "calc(56vmax / 10)",
          }}
        >
          <div className="w-full">
            <p className="text-white text-center px-10 lg:px-20 whitespace-pre-wrap text-[24px] font-extrabold">
              PORTFOLIO
            </p>
          </div>
          <div className="container lg:px-24 mx-auto">
            <div
              className="flex flex-wrap justify-center"
              style={{
                padding: "40px calc((100% - (355px * 3)) / 2)",
                paddingLeft: "0px !important",
              }}
            >
              {portfolio &&
                portfolio.map((item, index) => (
                  <div
                    className={`lg:w-1/4 w-1/3 py-5 px-5 lg:px-10 m-auto`}
                    key={index}
                  >
                    <div className="flex flex-row relative">
                      <a href={item.link} target="__blank">
                        <img
                          alt="gallery"
                          className="inset-0 w-full h-full object-contain object-center m-auto"
                          src={item.img}
                        />
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Portfolio;
