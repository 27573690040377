import React, { useState } from "react";
import parse from "html-react-parser";
import Box from "../../../assets/img/ventures/incubation/inactive.png";

export default function IncubationMobile() {
  const [open, setOpen] = useState(false);
  const [titleData, setTitle] = useState("");
  const [contentData, setContent] = useState("");
  function showData(title, content) {
    setOpen(false);
    setTimeout(() => {
      setOpen(true);
      setTitle(title);
      setContent(content);
    }, 50);
  }
  const list = [
    {
      title: "Project <br/>Advisory",
      vertical: true,
      leftAlignMobile: true,
      content:
        "We start with a general advisory session on the project positioning, product USP, market overview, and tech overview.",
    },
    {
      title: "Project Deep Dive",
      vertical: true,
      leftAlignMobile: false,
      content:
        "In this stage, our project and tech analysts will be performing a deep dive into the core of your project. This will encompass research into the project’s business model, technology, role in the Web3 space, potential market size, competitors in the industry and such.",
    },
    {
      title: "Technical Architecture",
      vertical: true,
      leftAlignMobile: true,
      content:
        "From their research into a project's technology, our analysts will advise and guide projects on ways to enhance their technical architecture. ",
    },
    {
      title: "Tokenomics Drafting",
      vertical: true,
      leftAlignMobile: false,
      content:
        "We will assist in designing the tokenomics based on amount raised, project’s roadmap, and other variables depending on the type of project.",
    },
    {
      title: "Product Design & Conceptualization",
      vertical: true,
      leftAlignMobile: true,
      content:
        "Next, our product expert will be looking into the product utility, market sentiment, user perception to help design and build a branding strategy around the product.",
    },
    {
      title: "Pre & Post Token Launch Strategy",
      vertical: true,
      content:
        "This will include strategizing for community building, community engagement, influencer outreach, establishing media presence, etc.",
    },
    {
      title: "Go-to-market Strategy Planning",
      vertical: true,
      leftAlignMobile: true,
      content:
        "Our marketing team will help prepare a tailored GTM strategy based on project type (Infrastructure, DeFi, GameFi, NFT, etc). ",
    },
    {
      title: "Partnership Outreach",
      vertical: true,
      leftAlignMobile: false,
      content:
        "Utilizing our vast network of projects in the Web3 space, we will recommend and connect projects to other projects for partnerships and/or investment opportunities.",
    },
    {
      title: "Whitepaper Drafting",
      leftAlignMobile: true,
      content:
        "We will be advising on whitepaper structure, project details, technical details as well as other aspects if deemed necessary. ",
    },
  ];
  return (
    <div>
      <div className="font-extrabold text-4xl h-auto">
        {list &&
          list.map((item, index) => (
            <div key={index} className={`h-[120px] justify-center w-full flex`}>
              <a
                className="flex flex-col justify-start items-center gap-3"
                type="button"
                onClick={() => showData(item.title, item.content)}
              >
                <div className="absolute">
                  <div
                    className={`boxIncubation${index} p-12 relative -top-[0.5rem]`}
                  />
                </div>
                <img
                  src={Box}
                  alt=""
                  className="z-10 group-hover:mix-blend-luminosity group-hover:grayscale-0 grayscale hover:grayscale-0"
                />

                {item.vertical ? (
                  <div
                    className={`block lg:hidden relative testden -bottom-[50px]  -right-[2%]`}
                  >
                    <div
                      className={`block lg:hidden h-[128px] border-white border-[0.2px] absolute bottom-[0%] -right-[120%] `}
                    />
                    {item.leftAlignMobile ? (
                      <div className="w-[10rem] lg:w-[13rem] px-5 text-[0.8rem] leading-[1.1rem] -ml-[12rem] text-white absolute -mt-[8rem]">
                        {parse(item.title)}
                      </div>
                    ) : (
                      <div className="w-[10rem] lg:w-[13rem] px-5 text-[0.8rem] leading-[1.1rem] -mr-[10rem] text-white absolute -mt-[7rem] ml-6">
                        {item.title}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {item.leftAlignMobile ? (
                      <div className="w-[10rem] lg:w-[13rem] px-5 text-[0.8rem] leading-[1.1rem] -ml-[12rem] text-white absolute -mt-[4rem]">
                        {parse(item.title)}
                      </div>
                    ) : (
                      <div className="w-[10rem] lg:w-[13rem] px-5 text-[0.8rem] leading-[1.1rem] -mr-[10rem] text-white absolute -mt-[7rem] ml-6">
                        {item.title}
                      </div>
                    )}
                  </div>
                )}

                {/* {item.vertical && (
                  <div
                    className={`block lg:hidden relative testden -bottom-[50px]  -right-[2%]`}
                  >
                    <div
                      className={`block lg:hidden h-[128px] border-white border-[0.2px] absolute bottom-[0%] -right-[120%] `}
                    />
                    {item.leftAlignMobile ? (
                      <div className="w-[10rem] lg:w-[13rem] px-5 text-[0.8rem] leading-[1.1rem] -ml-[12rem] text-white absolute -mt-[8rem]">
                        {parse(item.title)}
                      </div>
                    ) : (
                      <div className="w-[10rem] lg:w-[13rem] px-5 text-[0.8rem] leading-[1.1rem] -mr-[10rem] text-white absolute -mt-[7rem] ml-6">
                        {item.title}
                      </div>
                    )}
                  </div>
                )} */}
              </a>
            </div>
          ))}
        {open ? (
          <div className="text-white fixed top-[30%] items-center z-20 w-screen left-0 animated fadeInLeft">
            <div className="mx-3 rounded-xl px-10 lg:px-20 py-10 bg-gradient-to-b from-[#092E54] to-[#02162C] border-[#7B9FE1] border-2 border-opacity-20">
              <svg
                onClick={() => setOpen(false)}
                className="w-[2rem] z-20 float-right"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M175 175C184.4 165.7 199.6 165.7 208.1 175L255.1 222.1L303 175C312.4 165.7 327.6 165.7 336.1 175C346.3 184.4 346.3 199.6 336.1 208.1L289.9 255.1L336.1 303C346.3 312.4 346.3 327.6 336.1 336.1C327.6 346.3 312.4 346.3 303 336.1L255.1 289.9L208.1 336.1C199.6 346.3 184.4 346.3 175 336.1C165.7 327.6 165.7 312.4 175 303L222.1 255.1L175 208.1C165.7 199.6 165.7 184.4 175 175V175zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
                  fill="white"
                />
              </svg>
              <div className="text-2xl font-extrabold text-left pb-[0.5rem]">
                {parse(titleData)}
              </div>
              {/* <span className="text-[0.8rem] leading-[1.5rem] flex justify-start text-justify -tracking-[0.5px]">
                {contentData}
              </span> */}
              <div
                id="incubation-mobile-content"
                className="text-[0.8rem] leading-6 text-left whitespace-normal"
                style={{ wordSpacing: "0.15rem" }}
              >
                {contentData}
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}
