import React from "react";
// Import Swiper React components
/* eslint-disable import/no-unresolved */
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Navigation, Autoplay } from "swiper";
import Unchained from "../../assets/img/media/influencer-swiper/unchained.png";
import Uponly from "../../assets/img/media/influencer-swiper/uponly.png";
import CZ from "../../assets/img/media/influencer-swiper/cz.png";
import Buterin from "../../assets/img/media/influencer-swiper/buterin.png";
import Bankless from "../../assets/img/media/influencer-swiper/bankless.png";
import Gary from "../../assets/img/media/influencer-swiper/gary.png";

const influencer = [
  {
    cover: Unchained,
    title: "Unchained",
    desc: "Go to podcast",
    href: "https://open.spotify.com/episode/5AQgg2hPnaGMQwWegMSQ1o?si=CDQDMGT5RRKv0Is5Rxuc9w",
  },
  {
    cover: Uponly,
    title: "Uponly",
    desc: "Go to podcast",
    href: "https://open.spotify.com/episode/1NoHcxH7BAP3yfpOXqn0Un?si=0cZuhQ_jQ2y3h_VEU4Mbmg",
  },
  {
    cover: CZ,
    title: "CZ Binance",
    desc: "Go to podcast",
    href: "https://youtu.be/-1D3Arvu0Gg",
  },
  {
    cover: Buterin,
    title: "Vitalik Buterin",
    desc: "Go to podcast",
    href: "https://youtu.be/eSPaNVYEWn8",
  },
  {
    cover: Bankless,
    title: "Bankless",
    desc: "Go to podcast",
    href: "https://open.spotify.com/show/41TNnXSv5ExcQSzEGLlGhy?si=cp0UL2vvTyetQ-ZFRd_Gpw",
  },
  {
    cover: Gary,
    title: "Gary Vee",
    desc: "Go to podcast",
    href: "https://open.spotify.com/episode/5Me9RbHVxzk5yhyymkdGdp?si=W5-5E6bjQbqP72Lq9FAIwg",
  },
];
function Influencer() {
  return (
    <div className="w-full lg:min-h-[90vh] !pt-24 md:pt-0 flex flex-col justify-start bg-primary">
      <div className="w-screen flex justify-center items-center">
        <div className="container">
          <div className="text-white text-center md:text-left w-full flex justify-center md:flex-row flex-wrap items-center p-10 md:p-20 gap-0 !pt-0">
            <div className="">
              <div className="mb-5 md:mb-0">
                <div className="w-full md:w-auto h-full">
                  <div className="font-bold text-[1.9rem] md:text-[3rem] text-transparent bg-clip-text bg-gradient-to-b to-[#06456A] from-[#00DAB1] text-center">
                    Connect with{" "}
                    <span className="text-white font-bold">
                      Crypto & Blockchain
                    </span>
                  </div>
                  <div className="font-bold md:text-[3rem] text-center">
                    <p>Experts/Influencers</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Swiper
              autoHeight={true}
              effect={"cube"}
              grabCursor={true}
              loop={true}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              cubeEffect={{
                shadow: true,
                slideShadows: true,
                shadowOffset: 20,
                shadowScale: 0.94,
              }}
              navigation={true}
              modules={[Navigation, Autoplay]}
              className="swiperInfluencer"
              breakpoints={{
                360: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                },
              }}
            >
              {influencer &&
                influencer.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className={`w-full h-full absolute -z-10`} />
                    <div className="relative">
                      <a href={item.href} target="__blank">
                        <img src={item.cover} alt={`${item.cover}`} />
                        <h2 className="absolute font-extrabold text-2xl md:text-[1.5vw] text-white w-full bottom-10 left-1/2 -translate-x-1/2">
                          {item.title}
                        </h2>
                        <div className="absolute bottom-5 w-full font-extrabold text-xl md:text-[1.3vw] text-transparent bg-clip-text bg-gradient-to-b to-[#06456A] from-[#00DAB1]">
                          {item.desc}
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Influencer;
