import React from "react";
import ReactDOMServer from "react-dom/server";

import IncubationBox from "../../components/IncubationBox";
import IncubationMobile from "./mobile/incubation";

function Item(props) {
  return (
    <div className="w-[500px] max-w-[90vw] rounded-xl px-10 lg:px-20 py-10 bg-gradient-to-b from-[#092E54] to-[#02162C] border-[#7B9FE1] border-2 border-opacity-20">
      <div className="text-2xl font-extrabold">{props.title}</div>
      <br />
      <span>{props.content}</span>
    </div>
  );
}

function Incubation() {
  const list = [
    {
      title: "Project Advisory",
      right: true,
      rightMobilePos: true,
      isDesktop: true,
      tooltip: ReactDOMServer.renderToString(
        <Item
          title="Project Advisory"
          content="We start with a general advisory session on the project positioning, product USP, market overview, and tech overview."
        />
      ),
    },
    {
      title: "Project Deep Dive",
      right: true,
      rightMobilePos: true,
      rightPos: "127%",
      leftPos: "0%",
      bottomPos: "40%",
      verticalMobile: true,
      isDesktop: true,
      tooltip: ReactDOMServer.renderToString(
        <Item
          title="Project Deep Dive"
          content="In this stage, our project and tech analysts will be performing a deep dive into the core of your project. This will encompass research into the project’s business model, technology, role in the Web3 space, potential market size, competitors in the industry and such."
        />
      ),
    },
    {
      title: "Technical Architecture",
      right: true,
      leftPos: "90%",
      bottomPos: "25%",
      rightPos: "0%",
      verticalMobileLeft: true,
      leftEnd: true,
      isDesktop: true,
      tooltip: ReactDOMServer.renderToString(
        <Item
          title="Technical Architecture"
          content="From their research into a project's technology, our analysts will advise and guide projects on ways to enhance their technical architecture. "
        />
      ),
    },
    {
      title: "Product Design & Conceptualization",
      right: true,
      leftMobile: true,
      rightMobilePos: true,
      isDesktop: true,
      tooltip: ReactDOMServer.renderToString(
        <Item
          title="Product Design, Ideation and Conceptualization"
          content="Next, our product expert will be looking into the product utility, market sentiment, user perception to help design and build a branding strategy around the product."
        />
      ),
    },
    {
      title: "Tokenomics Drafting",
      isDesktop: true,
      leftEnd: true,
      verticalBottom: true,
      tooltip: ReactDOMServer.renderToString(
        <Item
          title="Tokenomics Drafting"
          content="We will assist in designing the tokenomics based on amount raised, project’s roadmap, and other variables depending on the type of project."
        />
      ),
    },
    {
      title: "Whitepaper Drafting",
      leftMobile: true,
      rightMobile: true,
      rightPos: "127%",
      leftPos: "0%",
      bottomPos: "25%",
      verticalMobile: true,
      isDesktop: true,
      tooltip: ReactDOMServer.renderToString(
        <Item
          title="Whitepaper Drafting"
          content="We will be advising on whitepaper structure, project details, technical details as well as other aspects if deemed necessary. "
        />
      ),
    },
    {
      title: "Go-to-market Strategy Planning",
      left: true,
      leftPos: "90%",
      bottomPos: "21%",
      rightPos: "0%",
      verticalMobileLeft: true,
      leftEnd: true,
      isDesktop: true,
      tooltip: ReactDOMServer.renderToString(
        <Item
          title="Go-to-market Strategy Planning"
          content="Our marketing team will help prepare a tailored GTM strategy based on project type (Infrastructure, DeFi, GameFi, NFT, etc). "
        />
      ),
    },
    {
      title: "Partnership Outreach",
      left: true,
      rightEnd: true,
      leftMobile: true,
      isDesktop: true,
      tooltip: ReactDOMServer.renderToString(
        <Item
          title="Partnership Outreach"
          content="Utilizing our vast network of projects in the Web3 space, we will recommend and connect projects to other projects for partnerships and/or investment opportunities."
        />
      ),
    },
    {
      title: "Pre & Post Token Launch Strategy",
      left: true,
      leftLast: true,
      rightDesktop: true,
      isDesktop: true,
      tooltip: ReactDOMServer.renderToString(
        <Item
          title="Pre & Post Token Launch Strategy"
          content="This will include strategizing for community building, community engagement, influencer outreach, establishing media presence, etc."
        />
      ),
    },
    {
      title: null,
    },
  ];

  return (
    <div className="">
      <div className="pt-28 lg:pb-0 text-white w-screen  hidden lg:block">
        <div className="font-extrabold text-4xl">
          <span>Incubation </span>
          <span className="text-transparent bg-clip-text bg-gradient-to-b from-[#047F86] to-[#00DAB1]">
            Kickoff
          </span>
        </div>
        <div
          className="py-8 flex flex-row flex-wrap justify-center lg:justify-between scale-[0.89] origin-top-left lg:scale-100
      lg:mx-[10rem]"
        >
          {list &&
            list.map((item, index) => (
              <div
                key={index}
                className={`lg:h-[240px] justify-center w-1/2 lg:w-1/5 ${
                  item.title ? "flex" : "hidden lg:flex"
                }`}
              >
                <IncubationBox item={item} />
              </div>
            ))}
        </div>
      </div>
      <div className="pt-5 lg:pb-0 text-white lg:hidden">
        <IncubationMobile />
      </div>
    </div>
  );
}

export default Incubation;
