import React from "react";

function NotFound() {
  return (
    <div className="flex flex-col items-center h-[100vh] bg-primary">
      <div className="flex flex-col items-center justify-center mt-10 h-full gap-10 text-white">
        <div className="text-6xl font-extrabold text-[60px] text-transparent bg-clip-text bg-gradient-to-b from-[#06456A] to-[#00DAB1]">
          404
        </div>
        <div className="text-4xl">Page Not Found!</div>
        <a
          href="/"
          className="px-6 py-3 rounded-md text-white hover:shadow-xl bg-header hover:bg-accent"
        >
          Go Home
        </a>
      </div>
    </div>
  );
}

export default NotFound;
