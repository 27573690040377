import React from "react";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Intro from "./intro";
import Ethos from "./ethos";
import Incubation from "./incubation";
import Projects from "./projects";
import Development from "./development";

function Lab() {
  return (
    <div className="w-full h-screen flex flex-col justify-start bg-primary">
      <Navbar />
      <div className="w-screen flex justify-center items-center">
        <div className="container">
          <Intro />
          <Ethos />
          <Incubation />
          <Projects />
          <Development />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Lab;
